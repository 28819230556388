.selected-client {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.aside:hover .selected-client {
  opacity: 1;
}

.selected-client-close {
  transform: translateX(36%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.aside:hover .selected-client-close {
  transform: translateX(10%);
}
