.multi-select {
    --rmsc-h: 42px;
    --rmsc-border: #e4e6ef;
    
    .item-renderer {
        align-items: center;
    }

    .item-renderer input[type="checkbox"] {
        margin-right: 1rem;
    }
}