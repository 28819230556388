:not(.Toastify__bounce-exit--bottom-left).alarm {
  animation: shake 4s cubic-bezier(0.34, 0.07, 0.19, 0.97) forwards infinite;
  animation-delay: 4s;
  pointer-events: all;
  cursor: pointer;
  background-color: rgb(223, 238, 254);
  border: 2px solid #009ef7;
}

:not(.Toastify__bounce-exit--bottom-left).carrot {
  background-color: rgb(248, 225, 182);
  border-color: rgb(255, 128, 2);
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(10px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(10px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(10px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
